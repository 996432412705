/*
 * @Description: 
 * @Author: fely
 * @Date: 2020-08-05 17:26:19
 * @LastEditTime: 2021-01-30 09:32:42
 * @LastEditors: fely
 */
const tockenKey = "tocken"

export function setTocken(tocken) {
  return localStorage.setItem(tockenKey, tocken)
}

export function getTocken() {
  return localStorage.getItem(tockenKey)
}