/*
 * @Description: 
 * @Author: fely
 * @Date: 2021-02-06 10:01:31
 * @LastEditTime: 2021-02-06 10:01:31
 * @LastEditors: fely
 */
const install = (Vue) => {
  const Bus = new Vue({
    methods: {
      on(event, ...args) {
        this.$on(event, ...args);
      },
      emit(event, callback) {
        this.$emit(event, callback);
      },
      off(event, callback) {
        this.$off(event, callback);
      }
    }
  })
  Vue.prototype.$bus = Bus;
}
export default install;