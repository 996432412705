/*
 * @Description: vuex存储器
 * @Author: fely
 * @Date: 2020-06-05 11:14:29
 * @LastEditTime: 2021-02-06 12:05:07
 * @LastEditors: fely
 */
import Vue from 'vue'
import Vuex from 'vuex'

import { codeLogin, loginOut } from '@/api/login.js'

import { setTocken } from '../utils/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
    // 登录
    Login({commit},submitForm) {
      // const username = userInfo.username.trim();
      // const password = userInfo.password;
      // const code = userInfo.code;
      console.log(commit)
      return new Promise((resolve, reject) => {
        
          codeLogin(submitForm)
            .then((res) => {
              // setTocken(res.data.token.principal)
              localStorage.setItem('userid', res.data.SysUser.id)
              localStorage.setItem('userImg', res.data.SysUser.headImage)
              resolve(res.code);
            }).catch((error) => {
              console.log(error)
              reject(error);
            });
      });
    },
    // 退出系统
    LogOut() {
      return new Promise((resolve, reject) => {
        loginOut()
          .then(() => {
            setTocken('')
            localStorage.clear()
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  modules: {},
});