/*
 * @Description: 路由配置
 * @Author: fely
 * @Date: 2020-06-05 11:14:29
 * @LastEditTime: 2021-02-06 17:05:50
 * @LastEditors: fely
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { getTocken } from '../utils/auth'
Vue.use(VueRouter)

const index = () =>
  import("views/index")
const post = () =>
  import('views/post')
const member = () =>
  import('views/member')
const position = () =>
  import('views/position')
const slip = () =>
  import('views/slip')
const vip = () =>
  import('views/vip')
const posList = () =>
  import('views/posList')
const posDetail = () =>
  import('views/posDetail')
const gjy = () =>
  import('views/gjy')

const routes = [{
  path: "/",
  redirect: "/index",
},
{
  path: "/index",
  component: index,
},
{
  path: '/post',
  component: post
},
{
  path: '/member',
  component: member
},
{
  path: '/position',
  component: position
},
{
  path: '/slip',
  component: slip
},
{
  path: '/vip',
  component: vip
},
{
  path: '/posList',
  component: posList
},
{
  path: '/posDetail',
  component: posDetail
},
{
  path: '/gjy',
  component: gjy
}
];

const router = new VueRouter({
  // mode: 'history',
  // scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes,
});

const whiteList = [
  "/index",
  "/vip",
  "/position",
  "/slip",
  "/member",
  "/posList",
  "/posDetail",
  "/post",
  "/gjy"
];

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (getTocken()) {
    if (to.path === '/login') {
      next({ path: "/main/payslip" });
      NProgress.done();
    } else {
      next();
      NProgress.done();
    }

  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      // next('/login'); // 否则全部重定向到登录页
      // Notification.info({
      //   title: '请登录'
      // })
      // next('/index');
      // Vue.prototype.$bus.emit("showLogin")
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router