/*
 * @Description: anxios封装
 * @Author: fely
 * @Date: 2020-06-08 17:39:33
 * @LastEditTime: 2021-02-06 12:07:14
 * @LastEditors: fely
 */
import axios from "axios";
import qs from "qs";
// import router from '@/router'
import { setTocken } from '../utils/auth'
import {  Notification } from 'element-ui'
import { getTocken } from '../utils/auth'

let pending = []; //声明一个数组用于存储每个ajax请求的取消函数和ajax标识
let cancelToken = axios.CancelToken;//初始化取消请求的构造函数
let arr = []//区分是请求还是响应的头部
let removePending = (config, f) => {
  arr = config.url.split('/api');
  arr = arr[arr.length - 1]
  let flagUrl = arr + '&' + config.method
  if (pending.indexOf(flagUrl) !== -1) {
    if (f) {
      f()
    } else {
      pending.splice(pending.indexOf(flagUrl), 1)
    }
  } else {
    if (f) {
      pending.push(flagUrl)
    }
  }
}


export function request(config) {
  // 1.创建axios的实例
  const instance = axios.create({
    timeout: 10000,
  });

  // 2.axios的拦截器
  // 2.1.请求拦截的作用
  instance.interceptors.request.use(
    (config) => {
      if (config.method === "post") {
        config.data = qs.stringify(config.data);
        config.cancelToken = new cancelToken((c) => {
          removePending(config, c);
        })
      }
      if (getTocken()) {
        config.headers.Authorization = getTocken();
      }
      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  // 2.2.响应拦截
  instance.interceptors.response.use(
    (res) => {
      if (res.headers.authorization) {
        // console.log(res.config.headers.Authorization)
        setTocken(res.headers.authorization)
      }
      if (res.config.method === 'post') {
        removePending(res.config)
      }
      // 未设置状态码则默认成功状态
      const code = res.data.code || 200;
      // 获取错误信息
      const message = res.data.message || res.data.msg
      if (code === 203) {
        localStorage.clear()
        Notification.info({
          title: '登录已过期，请重新登录'
        })
        // router.replace('/index')
        // window.location.reload()
      } else if (code == 201 || code == 404) {
        // console.log('空白')
        Notification.error({
          title: message
        })

      } else if (code != 200) {
        return Promise.reject('error')
      } else {
        return res.data
      }
      return res.data;
    },
    (err) => {
      console.log('err' + err)
      // Message({
      //     message: err.message,
      //     type: 'error',
      //     duration: 5 * 1000
      // })
    }
  );

  // 3.发送真正的网络请求
  return instance(config);
}