/*
 * @Description: 登录
 * @Author: fely
 * @Date: 2021-01-30 13:43:50
 * @LastEditTime: 2021-02-05 10:59:19
 * @LastEditors: fely
 */
import {request} from './index.js';

// 检测 用户是否已注册
export function isReg(phone){
  return request({
    url: '/api/sysUser/verifyUser',
    method: 'POST',
    data: {
      phone
    }
  })
}

// 获取验证码
export function obtainCode(telephone) {
  return request({
    url: '/api/sysUser/obtainveritycode',
    method: 'POST',
    data: {
      telephone
    }
  })
}

// 验证码登录
export function codeLogin(data) {
  return request({
    url: '/api/sysUser/loginveritycode',
    method: 'POST',
    data
  })
}

// 退出登录
export function loginOut() {
  return request({
    url: '/api/sysUser/logout',
    method: 'GET'
  })
}

// 获取个人信息
export function getUserInfo(userid) {
  return request({
    url: '/api/sysUser/selectSysUser',
    method: 'GET',
    params: {
      userid
    }
  })
}