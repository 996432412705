<!--
 * @Description: 
 * @Author: fely
 * @Date: 2021-01-29 16:29:35
 * @LastEditTime: 2021-01-30 10:14:15
 * @LastEditors: fely
-->
<template>
  <div id="app">
    <router-view :key="key"/>
  </div>
</template>

<script>
export default {
  name: "App",
  data(){
    return {
     
    }
  },
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
  height: 100vh;
}
</style>
